import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Avon Research</h1>
      </header>
      <main className="App-content">
        <p>Content coming soon!</p>
        {/* <button onClick={() => alert("Hello from React!")}>Click Me!</button> */}
      </main>
      <footer className="App-footer">
        <p>&copy; 2023 Avon Research Pty Ltd</p>
      </footer>
    </div>
  );
}

export default App;
